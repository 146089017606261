import "./App.css";
import WorkLog from "./WorkLog";
import { useEffect, useState } from "react";

function App() {
  const [isCorrectPasscode, setIsCorrectPasscode] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const passcode = params.get("passcode");

    if (passcode === "hei_it_is_me") {
      setIsCorrectPasscode(true);
    }
  }, []);

  return (
    <div className="App">
      {isCorrectPasscode ? <WorkLog /> : <h1>Hei you went wrong place!</h1>}
    </div>
  );
}

export default App;
