import React, { useEffect, createRef, useState } from "react";
import "./WorkLog.css";
import { db } from "./firebase";
import { getDoc, doc, setDoc, updateDoc } from "firebase/firestore";

const WorkLog = () => {
  const [input, setInput] = useState("");
  const [logs, setLogs] = useState([]);
  const [message, setMessage] = useState(null);
  const [logRefs, setLogRefs] = useState([]);

  useEffect(() => {
    console.log(logs.length);
    setLogRefs((refs) =>
      Array(logs.length)
        .fill()
        .map((_, i) => refs[i] || createRef())
    );
  }, [logs]);

  useEffect(() => {
    if (logRefs.length > 0) {
      logRefs[logRefs.length - 1].current.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [logRefs]);

  useEffect(() => {
    //get the logs from firebase, if not exist, set the initial log to firebase
    const fetchLogs = async () => {
      const logRef = doc(db, "private", "logs");
      const logSnap = await getDoc(logRef);
      if (logSnap.data().worklogs.length > 0) {
        setLogs(logSnap.data().worklogs);
      } else {
        console.log("set initial log");
      }
    };
    fetchLogs();
  }, []);

  //send the input to firebase
  const handleSend = async () => {
    if (input) {
      const newLog = {
        timestamp: new Date().toLocaleString(),
        content: input,
      };
      await updateDoc(doc(db, "private", "logs"), {
        worklogs: [...logs, newLog],
      });
      setLogs([...logs, newLog]);
      setMessage("Sent!");
      setTimeout(() => {
        setMessage(null);
      }, 2000);
    } else {
      setMessage("Please enter something!");
      setTimeout(() => {
        setMessage(null);
      }, 2000);
    }
    setInput("");
  };

  const handleKey = (e) => {
    if (e.key === "Enter") {
      handleSend();
    }
  };

  const handleInput = (e) => {
    setInput(e.target.value);
  };

  return (
    <div className="container">
      <h1>My Log</h1>
      <div className="logList">
        <ul>
          {logs.map((log, index) => (
            <li key={index} ref={logRefs[index]}>
              <span className="time">{log.timestamp}</span>
              <p className="content">{log.content}</p>
            </li>
          ))}
        </ul>
      </div>

      <div className="input">
        <input
          type="text"
          placeholder="Write something ..."
          value={input}
          onChange={handleInput}
          onKeyDown={handleKey}
        />
        <span>{message}</span>
      </div>
    </div>
  );
};

export default WorkLog;
